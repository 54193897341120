<template>
  <van-nav-bar title="发现" />
  <div class="discover-page">
    <van-tabs v-model:active="active" class="custom-tabs" type="card" @change="changeMaobao">
      <van-tab title="香港六合彩">
        <div class="mabao-content">
          <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="initLotteryMaobao(true)">
            <div class="maobao-box">
              <div class="maobao-item" v-for="(item, index) of mabaoList" :key="index">
                <div class="item-roundNo">第{{ item.RoundNo }}期</div>
                <div class="item-img">
                  <van-image :src="item.MaBaoImageUrl" width="160px" height="134px" fit="cover" @click="openNewsPopup(index)"></van-image>
                </div>
                <div class="item-title">{{ item.Title }}</div>
                <div class="item-info">
                  <div class="info-author">{{ item.Author }}</div>
                  <div :class="['info-like']" @click="getMabaoClickLike(index)"><van-icon name="good-job" :class="{ isLike: item.isLike }" style="margin-right: 2px" />{{ item.LikeCount }}</div>
                  <div :class="['likeTip', { showlikeTip: item.showTip }]">一天只能点赞一次</div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="澳门六合彩">
        <div class="mabao-content">
          <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="initLotteryMaobao(true)">
            <div class="maobao-box">
              <div class="maobao-item" v-for="(item, index) of mabaoList" :key="index">
                <div class="item-roundNo">第{{ item.RoundNo }}期</div>
                <div class="item-img">
                  <van-image :src="item.MaBaoImageUrl" width="160px" height="134px" fit="cover" @click="openNewsPopup(index)"></van-image>
                </div>
                <div class="item-title">{{ item.Title }}</div>
                <div class="item-info">
                  <div class="info-author">{{ item.Author }}</div>
                  <div :class="['info-like']" @click="getMabaoClickLike(index)"><van-icon name="good-job" :class="{ isLike: item.isLike }" style="margin-right: 2px" />{{ item.LikeCount }}</div>
                  <div :class="['likeTip', { showlikeTip: item.showTip }]">一天只能点赞一次</div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
  <van-popup v-model:show="showNewsPopup" closeable close-icon="close" position="bottom" :style="{ height: '100%' }">
    <van-nav-bar :title="active == 0 ? '香港六合彩' : '澳门六合彩'" />
    <div class="discover-popup">
      <div class="popup-header">
        <div class="title">
          第<span style="color: red">{{ newsDetailsPopup.RoundNo }}</span
          >期{{ newsDetailsPopup.Title }}
        </div>
        <div class="popup-info">
          <div class="item">
            <div class="label">{{ $moment(newsDetailsPopup.AddTime).format("YYYY/MM/DD") }}</div>
          </div>
          <div class="item">
            <div class="label" @click="getMabaoClickLike(activeIndex)">
              <van-icon name="good-job" :class="{ isLike: newsDetailsPopup.isLike }" style="margin-right: 2px" />{{ newsDetailsPopup.LikeCount }}
            </div>
            <div :class="['likeTip', { showlikeTip: newsDetailsPopup.showTip }]">一天只能点赞一次</div>
          </div>
        </div>
        <div class="popup-img">
          <van-image :src="newsDetailsPopup.MaBaoImageUrl" width="100%" fit="cover"></van-image>
        </div>
        <div class="popup-author">
          <span>发布者: </span><span class="author"> {{ newsDetailsPopup.Author }}</span>
        </div>
      </div>
      <div class="popup-body">
        <div class="popup-content">
          <div class="review">作者点评</div>
          <div class="content-text" v-html="newsDetailsPopup.ContentText"></div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getListLotteryMabao, getMabaoClickLike } from "@/api";
export default {
  name: "discoverView",
  components: {},
  data() {
    return {
      active: 0,
      loading: false,
      params: {
        PageNumber: 0,
        PageSize: 20,
        LotteryID: 12,
      },
      mabaoList: [],
      finished: false,
      showNewsPopup: false,
      newsDetailsPopup: [],
      isLike: false,
      activeIndex: 0,
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    async initLotteryMaobao(loadMore) {
      if (loadMore) {
        this.params.PageNumber += 1;
      } else {
        this.params.PageNumber = 1;
        this.articleList = [];
        this.finished = false;
      }
      this.loading = true;
      const res = await getListLotteryMabao(this.params);
      const newRes = res[0].map((item) => ({
        ...item,
        isLike: false,
        showTip: false,
      }));
      this.finished = res[0].length < this.params.PageSize;
      this.mabaoList.push(...newRes);
      this.loading = false;
    },
    async getMabaoClickLike(index) {
      const params = {
        Id: this.mabaoList[index].Id,
      };
      const res = await getMabaoClickLike(params);
      if (!this.mabaoList[index]) return;
      if (res.code == 20) {
        this.mabaoList[index].isLike = true;
        this.mabaoList[index].showTip = true;
        setTimeout(() => {
          this.mabaoList[index].showTip = false;
        }, 1000);
      } else {
        this.mabaoList[index].isLike = true;
        this.mabaoList[index].LikeCount = res;
      }
    },
    changeMaobao() {
      this.active === 0 ? (this.params.LotteryID = 12) : (this.params.LotteryID = 62);
      this.mabaoList = [];
      this.initLotteryMaobao(false);
    },
    openNewsPopup(index) {
      this.activeIndex = index;
      this.newsDetailsPopup = this.mabaoList[index];
      this.showNewsPopup = true;
    },
    formatDate(str) {
      const date = new Date(str);
      return date.toISOString().slice(0, 16).replace("T", " ");
    },
  },
};
</script>

<style lang="scss">
.discover-page {
  margin: 10px 10px 50px 10px;
}

.discover-popup {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;

  .popup-header {
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #252626;
      text-align: center;
      margin-bottom: 10px;
    }

    .popup-info {
      display: flex;
      justify-content: space-between;
      font-family: Cairo;
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      margin: 10px 0px;
    }

    .popup-img {
      margin: 20px 0px;
    }
    .popup-author {
      .author {
        color: #4880e0;
      }
    }
  }
  .popup-body {
    margin: 30px 0px;
    .popup-content {
      padding: 10px;
      border: 2px solid #bbbbbb;
      position: relative;
      .review {
        position: absolute;
        top: -12px;
        left: 20px;
        background-color: #ffffff;
        font-family: Cairo;
        font-size: 14px;
        font-weight: 700;
        z-index: 99;
        padding: 0px 4px;
        .content-text {
          width: 100%;
        }
      }
    }
  }
}

.mabao-content {
  padding: 10px;
  .maobao-box {
    display: grid;
    grid-template-columns: 50% 50%;
    .maobao-item {
      margin: 4px;
      background-color: #ffffff;
      text-align: center;
      .item-roundNo {
        background-color: #eef4ff;
        margin: 1px 1px 8px 1px;
        padding: 4px 0px;
        font-size: 12px;
        font-weight: 400;
      }
      .item-title {
        text-align: left;
        margin: 4px 8px;
        border-bottom: 1px solid #f7f7f7;
        font-size: 12px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
      .item-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 8px;
        position: relative;
        .info-author {
          color: #4880e0;
          font-size: 12px;
          font-weight: 400;
        }
        .info-like {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}

.custom-tabs {
  .van-tabs__nav {
    border-radius: 12px;
    .van-tab {
      color: #000;
      font-weight: 600;
    }
  }
  .van-tab--active {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: #ffffff !important;
  }
  .van-tab--card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.isLike {
  color: #ffba48;
}
.likeTip {
  display: none;
  color: red;
}

.showlikeTip {
  z-index: 999;
  display: block !important;
  position: absolute;
  right: 8px;
  opacity: 0;
  color: red;
  transform: translateY(20px); /* 初始位置稍微向下 */
  animation: slideInOut 2s cubic-bezier(0.4, 0, 0.2, 1) forwards; /* 2秒的动画 */
}

/* 定义显示和消失的动画 */
@keyframes slideInOut {
  0% {
    opacity: 0; /* 从完全透明开始 */
    transform: translateY(20px); /* 从下方开始 */
  }
  50% {
    opacity: 1; /* 完全不透明 */
    transform: translateY(-10); /* 移动到原位置 */
  }
  100% {
    opacity: 0; /* 最终变为完全透明 */
    transform: translateY(-20px); /* 向上移动 */
  }
}
</style>
